import { Grid } from '@mui/material';
import Router from 'next/router';

import styles from '../../styles/SubHeader.module.css';
import { pages } from '../Header';
const Solucoes = () => {
  return (
    <Grid
      item
      container
      direction={'column'}
      className={styles.solucoes}
      padding="27px 24px"
      gap={'24px'}
      width={'fit-content'}
      minWidth={'353px'}
      key={Math.random() * 100000}
    >
      <Grid item className="barlow--size18 bold">
        Nossas Soluções
      </Grid>
      {pages['Nossas Soluções'].map(solution => (
        <Grid
          style={{ cursor: 'pointer' }}
          onClick={() => {
            Router.push(`/${solution.path}`);
          }}
          item
          key={Math.random() * 1000000}
          className="open-sans--size16"
        >
          {solution.label}
        </Grid>
      ))}
    </Grid>
  );
};

export default Solucoes;
