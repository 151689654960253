import { ArrowBack, ArrowForward, ArrowForwardIos } from '@mui/icons-material';
import { Grid, IconButton, Menu, MenuItem } from '@mui/material';
import Image from 'next/image';
import Router from 'next/router';
import { Fragment, FunctionComponent, MouseEvent, useState } from 'react';

import bboost from '../public/img/bboost.png';
import buildr from '../public/img/buildr.png';
import close from '../public/img/mobile/close.png';
import menu from '../public/img/mobile/fi_menu.webp';
import trader from '../public/img/trader.png';
import valida from '../public/img/valida.png';
import zoug from '../public/img/zoug.png';
import { pages } from './Header';
import Solucoes from './SubHeader/Solucoes';
interface IMenuItemIcon {
  option: string;
  description?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSubmenu: (e?: any) => void;
  isReverse?: boolean;
  isSubmenu?: boolean;
  isProducts?: boolean;
  path?: string;
}

const Products = {
  buildr: (
    <Image src={buildr.src} alt="logos" height={'30.77px'} width={'21.54px'} />
  ),
  trader: (
    <Image src={trader.src} alt="logos" height={'32.16px'} width={'23.63px'} />
  ),
  zoug: (
    <Image src={zoug.src} alt="logos" height={'24.96px'} width={'31.54px'} />
  ),
  valida: (
    <Image src={valida.src} alt="logos" height={'31.43px'} width={'32.31px'} />
  ),
  'business boost': (
    <Image src={bboost.src} alt="logos" height={'31.36px'} width={'31.12px'} />
  ),
};

const MenuItemIcon: FunctionComponent<IMenuItemIcon> = ({
  option,
  description,
  handleSubmenu,
  isReverse,
  isSubmenu,
  isProducts,
  path,
}) => {
  return (
    <MenuItem
      onClick={() => path && Router.push(path)}
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Grid
        container
        item
        direction="column"
        alignContent={'center'}
        alignItems={'center'}
        justifyContent={'center'}
        onClick={handleSubmenu}
        gap={'16px'}
      >
        <Grid
          item
          container
          direction={isReverse ? 'row-reverse' : 'row'}
          alignContent={'center'}
          alignItems={isProducts ? 'flex-start' : 'center'}
          justifyContent={'center'}
          gap={'8px'}
          wrap="nowrap"
        >
          {isProducts && (
            <Grid item width={'40px'} height={'40px'}>
              {Products[option]}
            </Grid>
          )}
          <Grid item container direction="column">
            <Grid item className={'barlow--size18 bold'}>
              {option}
            </Grid>
            {description && (
              <Grid
                item
                className={'open-sans--size14'}
                whiteSpace="break-spaces"
                width="216px"
              >
                {description}
              </Grid>
            )}
            {option === 'business boost' && (
              <Grid
                item
                container
                borderRadius={'34px'}
                style={{ backgroundColor: 'var(--background-medium)' }}
                padding="4px 8px"
                alignItems={'center'}
                justifyContent={'center'}
                alignContent={'center'}
              >
                <Grid item className="barlow--size14">
                  <b>Exclusivo</b> para clientes Devyx
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item>
            {isSubmenu ? (
              <ArrowForwardIos htmlColor="var(--primary-blue)" />
            ) : (
              <IconButton
                style={{
                  borderRadius: '100%',
                  backgroundColor: 'var(--background-medium)',
                }}
              >
                {isReverse ? (
                  <ArrowBack htmlColor="var(--primary-blue)" />
                ) : (
                  <ArrowForward htmlColor="var(--primary-blue)" />
                )}
              </IconButton>
            )}
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          minHeight="1px"
          width={'100%'}
          style={{ backgroundColor: 'var(--background-medium)' }}
        />
      </Grid>
    </MenuItem>
  );
};

const MobileMenu = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [submenu, setSubmenu] = useState<null | string>(null);
  const [open, setOpen] = useState(Boolean(anchorEl));
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSubmenu(null);
    setOpen(false);
  };

  const handleSubmenu = e => {
    setSubmenu(e.target.innerText);
  };

  const handleBack = () => {
    setSubmenu(null);
  };

  return (
    <Fragment>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? 'long-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        {open ? (
          <Image src={close.src} alt="menu" height={'24px'} width={'24px'} />
        ) : (
          <Image src={menu.src} alt="menu" height={'24px'} width={'24px'} />
        )}
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          'aria-labelledby': 'long-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            height: 'calc(100vh - 72px)',
            width: '100vw',
          },
        }}
      >
        {submenu ? (
          <Grid
            container
            direction={'column'}
            justifyContent={'center'}
            alignContent={'center'}
            alignItems={'center'}
          >
            <MenuItemIcon
              option={submenu}
              handleSubmenu={handleBack}
              isReverse
            />
            {pages[submenu].map(option => (
              <MenuItemIcon
                key={option.label}
                option={option.label}
                description={option.description}
                handleSubmenu={handleClose}
                isSubmenu
                isProducts={submenu === 'O que Devyx oferece'}
                path={option.path}
              />
            ))}
            {submenu === 'O que Devyx oferece' && <Solucoes />}
          </Grid>
        ) : (
          <Grid
            container
            direction={'column'}
            justifyContent={'center'}
            alignContent={'center'}
            alignItems={'center'}
          >
            <MenuItemIcon
              option={'Home'}
              path="/"
              handleSubmenu={handleClose}
            />
            {Object.keys(pages).map(option => {
              if (option !== 'Nossas Soluções') {
                return (
                  <MenuItemIcon
                    option={option}
                    key={option}
                    handleSubmenu={handleSubmenu}
                  />
                );
              }
            })}
          </Grid>
        )}
      </Menu>
    </Fragment>
  );
};

export default MobileMenu;
